import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import "../styles/blueCircle.scss";

export default function AlumniQuotes({ type, children }) {
  function getRandomInt(max, slice) {
    var n = Math.floor(Math.random() * Math.floor(max)) - slice;
    return n < 0 ? 0 : n;
  }

  const quotes = useStaticQuery(graphql`
    query AlumniQuotes {
      all: allMdx(
        sort: { fields: [frontmatter___title], order: DESC }
        filter: { frontmatter: { tags: { eq: "alumni_quote" } } }
      ) {
        nodes {
          frontmatter {
            title
            headshot
            author
            credentials
          }
          body
        }
      }
      limit: allMdx(
        filter: { frontmatter: { tags: { eq: "alumni_quote" } } }
        sort: { fields: frontmatter___title, order: DESC }
      ) {
        nodes {
          frontmatter {
            title
            headshot
            author
            credentials
          }
          body
        }
      }
    }
  `);

  function AllQuotes() {
    return (
      <Row className="alumni-quotes">
        {quotes.all.nodes.map((node, i) => (
          <Col key={i++} xs={12} lg={6} className="quote mb-4 px-4">
            <Row className="mb-n4 ps-5">
              <Col xs={12} className="mb-3 mb-md-0 px-0">
                <span className="blue-circle me-n3">
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    color="white"
                    size="6x"
                    fixedWidth
                  />
                </span>
                {node.frontmatter.headshot && (
                  <img
                    src={node.frontmatter.headshot}
                    alt={`Headshot of ${node.frontmatter.title}`}
                    className="img-fluid img-rounded stacked-img"
                    width="80"
                    height="80"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="p-5 white-bg">
                <MDXRenderer>{node.body}</MDXRenderer>
                <h5>—{node.frontmatter.author}</h5>
                <h6>{node.frontmatter.credentials}</h6>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }

  function QuotesPanel() {
    function groupIntoTwo(children) {
      const output = [];
      let currentGroup = [];

      children.forEach((child, index) => {
        currentGroup.push(child);

        if (index % 2 === 1) {
          output.push(currentGroup);
          currentGroup = [];
        }
      });

      return output;
    }
    const randomizedPosts = quotes.limit.nodes.sort(() => 0.5 - Math.random());
    const limitedPosts = randomizedPosts.slice(0, 13);
    return (
      <Container fluid="lg">
        <Row className="py-xxl-5 py-md-4 mx-3 mx-lg-auto">
          <Col className="opaque-gray-bg p-xl-5 p-4 rounded">
            <Row>
              <Col>
                <h2>Student Testimonials</h2>
                <Row className="align-items-center">
                  <Col xs={12} lg={8}>
                    <p>
                      Read key takeaways from Meridian graduate students about
                      their educational experience and why they chose Meridian's
                      curriculum, faculty, community, and learning platform.
                    </p>
                  </Col>
                  <Col lg={4} className="d-none d-lg-block text-end">
                    <Link
                      to="/admissions/resources/alumni-and-students/experiences"
                      className="btn styled-link"
                    >
                      Read More Student Experiences{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_534_6047)">
                          <path
                            d="M3.20918 0.468994L8.20923 5.46899L3.20918 10.469L1.7908 9.05052L5.37237 5.46899L1.7908 1.88747L3.20918 0.468994Z"
                            fill="#0078AE"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_534_6047">
                            <rect
                              width="10"
                              height="10"
                              fill="white"
                              transform="matrix(-1 0 0 1 10 0.468994)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="py-4 mb-3">
                <span className="blue-border-bottom"></span>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs={12}>
                <Carousel className="alumni-quotes">
                  {groupIntoTwo(limitedPosts).map((node, i) => (
                    <Carousel.Item key={i++} className="quote mb-4">
                      <>
                        {node.map((quote, i) => (
                          <Col
                            xs={12}
                            lg={6}
                            key={i++}
                            className={i % 2 === 0 ? "d-none d-lg-block" : null}
                          >
                            <Row className="mb-n4 ps-5">
                              <Col xs={12} className="mb-3 mb-md-0 px-0">
                                <span className="blue-circle me-n3">
                                  <FontAwesomeIcon
                                    icon={faQuoteRight}
                                    color="white"
                                    size="6x"
                                    fixedWidth
                                  />
                                </span>
                                {quote.frontmatter.headshot && (
                                  <img
                                    src={quote.frontmatter.headshot}
                                    alt={`Headshot of ${quote.frontmatter.title}`}
                                    className="img-fluid img-rounded stacked-img"
                                    width="80"
                                    height="80"
                                  />
                                )}
                              </Col>
                            </Row>
                            <Row
                              className={i % 2 === 0 ? "ms-lg-2" : "me-lg-2"}
                            >
                              <Col xs={12} className="p-5 white-bg">
                                <MDXRenderer>{quote.body}</MDXRenderer>
                                <h5>—{quote.frontmatter.author}</h5>
                                <h6>{quote.frontmatter.credentials}</h6>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  function StoriesQuote() {
    return (
      <Col className="stories-quote my-lg-5 my-4">
        <Row className="ps-5">
          <Col xs={12} className="mb-3 mb-md-0 px-0">
            <span className="green-circle me-n3">
              <FontAwesomeIcon
                icon={faQuoteRight}
                color="white"
                size="6x"
                fixedWidth
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="p-5 light-gray-bg">
            <p>{children}</p>
          </Col>
        </Row>
      </Col>
    );
  }

  if (type === "all") {
    return <AllQuotes />;
  }
  if (type === "panel") {
    return <QuotesPanel />;
  }
  if (type === "stories") {
    return <StoriesQuote />;
  } else {
    return null;
  }
}
